//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  methods: {
    refresh() {
      this.$router.push({ path: "/index" });
    }
  }
};
